import { Link } from "react-router-dom";
import styles from "./Footer.module.css";

export default function Footer() {
    return (
        <div className={styles.Footer}>
            <div className={styles.content}>
                <div className={styles.copy}>© 2024 Yorta</div>
                <div className={styles.links}>
                    <a href="/docs/offer.pdf" target="_blank" rel="noopener noreferrer" className={styles.link}>Оферта</a>
                    <a href="/docs/privacy_policy.pdf" target="_blank" rel="noopener noreferrer" className={styles.link}>Политика конфеденциальности</a>
                    <Link to="/about-yorta" className={styles.link}>О сервисе</Link>
                    <a href="https://t.me/Yortakz" target="_blank" rel="noopener noreferrer" className={styles.link}>Служба поддержки в Телеграм</a>
                </div>
            </div>
        </div>
    );
}
