let APP_CONFIG = {
    "BASE_URL": "https://api.services.test.emoney.tools",
    "INITIAL_DATA": {
        account: "",
        order: "",
        amount: ""
    }
};

if (process.env.REACT_APP_WEBPACK_CONFIG) {
    const envConfig = JSON.parse(process.env.REACT_APP_WEBPACK_CONFIG);

    APP_CONFIG.BASE_URL = envConfig.BASE_URL;
    
    if (envConfig.ENV === "test") {
        APP_CONFIG.INITIAL_DATA = {
            account: "7020672426",
            order: "837332907",
            amount: "10"
        };
    }
}

export const BASE_URL = APP_CONFIG.BASE_URL;
export const INITIAL_DATA = APP_CONFIG.INITIAL_DATA;

console.log(process.env);
console.log("BASE_URL", BASE_URL);
console.log("INITIAL_DATA", INITIAL_DATA);
