import Page from "Blocks/Page/Page";
import styles from "./AboutUs.module.css";

function AboutUs() {
  return (
    <>
      <Page title="Сервис Yorta.kz" subtitle="Удобные платежи для ваших заказов" type="about">
        <div className={styles.container}>
            <div className={styles.aboutInfo}>
            <p className={styles.paragraph}>
            Добро пожаловать на Yorta.kz — сервис, который делает оплату заказов
            простой и безопасной. Оплачивайте свои заказы банковской картой или
            с помощью QR-кода быстро и удобно.
          </p>
            </div>
          

          <h2 className={styles.sectionTitle}>Как это работает:</h2>
          <ol className={styles.list}>
            <li className={styles.listItem}>
              <span className={styles.bold}>Ввод данных:</span> укажите номер
              клиента, номер заказа и сумму оплаты.
            </li>
            <li className={styles.listItem}>
              <span className={styles.bold}>Выбор способа оплаты:</span>{" "}
              выберите оплату банковской картой или QR-кодом.
            </li>
            <li className={styles.listItem}>
              <span className={styles.bold}>Подтверждение платежа:</span>{" "}
              завершите оплату и получите подтверждение.
            </li>
          </ol>

          <h2 className={styles.sectionTitle}>Безопасность</h2>
          <p className={styles.paragraph}>
            Все платежи на Yorta.kz защищены современными технологиями
            шифрования, обеспечивая безопасность ваших данных.
          </p>

          <h2 className={styles.sectionTitle}>Поддержка клиентов</h2>
          <p className={styles.paragraph}>
            Если у вас возникли вопросы, наша служба поддержки всегда готова
            помочь. Свяжитесь с нами в любое время, и мы решим ваш вопрос как
            можно быстрее.
          </p>

          <p className={styles.paragraph}>
            Попробуйте Yorta.kz уже сегодня и оцените удобство наших услуг!
          </p>

          <h2 className={styles.sectionTitle}>Контактная информация</h2>
          <p className={styles.contactInfo}>
            <span className={styles.bold}>Номер телефона:</span>{" "}
            <a href="tel:+77073328450" className={styles.contactLink}>
              +77073328450
            </a>
          </p>
          <p className={styles.contactInfo}>
            <span className={styles.bold}>Почта:</span>{" "}
            <a href="mailto: info@smarttools.kz" className={styles.contactLink}>
              info@smarttools.kz
            </a>
          </p>
        </div>{" "}
      </Page>
    </>
  );
}

export default AboutUs;
